/* contact section */

.contact-section {
    background-color: #1C1C1C;
    color: #EAEAEA;
    padding: 30px;
    font-family: 'Comfortaa', 'sans-serif';
    font-size: 16px;
}

.contact-content {
    display: flex; 
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
}

.button-container {
    display: flex;
    justify-content: center;
}

.contact-content button {
    background-color: #1C1C1C;
    border: 2px solid #AB90F1;
    border-radius: 5px;
    font-family: 'Comfortaa', sans-serif;
    font-size: 20px;
    cursor: pointer;
    width: 150px;
    color: #EAEAEA;
    margin-top: 10px;
}

.contact-content button:hover {
    background-color: #AB90F1;
    color: #1C1C1C;
}

.contact-heading-container {
    position: relative;
    width: 100%;
    text-align: center;
    height: 100px; /* Adjust the height to accommodate both texts */
}

#contact-heading-1 {
    font-family: 'Marcellus', serif;
    font-size: 48px;
    color: #D1C4E9;
    position: absolute;
    top: 0;
    left: 10px;
    margin: 0;
    z-index: 0;
    
}

#contact-heading-2 {
    font-family: 'Parisienne', cursive;
    font-size: 60px;
    color: #AB90F1;
    position: absolute;
    top: -3px; 
    left: 150px; 
    margin: 0;
    z-index: 1;
}

.contact-form {
    flex: 1;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    width: 100%; /* Ensure form takes full width of its container */
}

.contact-text {
    margin-bottom: 20px;
}

.contact-text h2 {
    font-size: 30px;
    color: #EAEAEA;
    font-family: 'Marcellus', serif;
    margin-bottom: 10px;
}

.contact-form form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%; /* Ensure form takes full width */
}

.contact-form input, .contact-form textarea {
    color: #1c1c1c;
    resize: vertical;
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-family: 'Comfortaa', sans-serif;
    box-sizing: border-box; /* Include padding in width calculation */
}


.contact-form textarea {
    min-height: 150px;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
    color: #AB90F1;
}

.contact-image {
    flex: 1;
    max-width: 475px;
}

.contact-image img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 1100px) {
    .contact-content {
        flex-direction: column;
        align-items: center;
    }

    .contact-form, .contact-image {
        width: 100%;
        max-width: 600px;
    }

    .contact-text, .contact-form form {
        width: 100%;
    }

    .contact-text {
        text-align: left;
    }
}

@media (max-width: 480px) {
    .contact-section {
        padding: 15px;
    }

    .contact-form {
        padding: 0; /* Remove any padding on the form container */
    }

    .contact-form input, .contact-form textarea {
        padding: 10px;
        font-size: 14px; /* Slightly smaller font for very small screens */
    }

    .contact-text h2 {
        font-size: 24px;
    }

    .contact-text p {
        font-size: 14px;
    }

    .contact-form textarea {
        min-height: 100px;
    }

    .contact-form button[type="submit"] {
        width: 100%;
        padding: 12px;
        font-size: 16px;
    }
}