/* Project 2 Styles */

/* whole section styles */
.project2-section {
    background-color: #1C1C1C;
    color: #EAEAEA;
    padding: 20px;
    font-family: 'Comfortaa', 'sans-serif';
    display: flex;
    justify-content: center;
}

/* title styles */
.project2-heading-container {
    position: relative;
    width: 100%;
    margin-left: -25px;
    height: 50px; 
}

#project2-heading-1 {
    font-family: 'Marcellus', serif;
    font-size: 48px;
    color: #D1C4E9;
    position: absolute;
    top: 0;
    left: 20px;
    margin: 0;
    z-index: 1;
}

#project2-heading-2 {
    font-family: 'Parisienne', cursive;
    font-size: 65px;
    color: #AB90F1;
    position: absolute;
    top: -12px; 
    left: 250px; 
    margin: 0;
    z-index: 0;
}

#project2-heading-2-shortened {
    display: none;
}

/* heading styles */
.project2-section h1 {
    font-family: 'Parisienne', cursive;
    font-size: 48px;
    color: #AB90F1;
}

.project2-section h2 {
    font-family: 'Marcellus', serif;
    font-size: 28px;
    margin-bottom: 0px;
}

#two-overview-h3 {
    font-family: Comfortaa, sans-serif !important;
    font-size: 20px !important;
}

.project2-section h3 {
    font-size: 22px;
    font-family: 'Marcellus', serif;
}


/* paragraph styling */
.project2-section p {
    font-size: 16px;
    line-height: 1.5;
}

/* list styling */
.project2-section li {
    font-size: 16px;
    line-height: 1.5;
}

/* link styling */
.project2-section a {
    color: #AB90F1;
    text-decoration: none;
}

.project2-section a:hover {
    text-decoration: underline;
}

/* button styling */
.project2-section button {
    background-color: #1C1C1C;
    border: 2px solid #AB90F1;
    border-radius: 5px;
    padding: 10px;
    font-family: 'Comfortaa', sans-serif;
    cursor: pointer;
    width: 200px;
    color: #EAEAEA;
    font-size: 16px;
}

.project2-section button:hover {
    background-color: #AB90F1;
    color: #1C1C1C;
}

/* content styling */
.project2-content {
    flex: 1;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* intro section styling */
.two-intro-section {
    display: flex;
    background-color: #EAEAEA;
    border-radius: 20px;
}

.two-title {
    margin-top: 20px;
}

.two-intro-text {
    color: #1C1C1C;
    padding-right: 10px;
}

.two-intro-image {
    margin-right: 1.5rem;
}

.two-intro-image img {
    width: 100%;
    height: 100%;;
    border-radius: 20px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    min-width: 400px;
    vertical-align: bottom;
    object-fit: cover;
}

#two-prototype-line {
    color: #AB90F1;
    padding-right: 10px;
}

.two-prototype-link {
    display: flex;
    padding: 5px;
    justify-content: center;
}

.two-prototype-link a {
    padding: 5px;
}

/* goals/target audience section styling */
.two-goals-audience-section {
    display: flex;
}

.two-audience {
    padding-left: 10px;
}

/* wireframes section styling */
.two-wireframes-images {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    gap: 10px;
}

.wireframe-image {
    width: 32%;
}

.two-wireframes-images img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    border: 2px solid #7C4DFF;
}

.caption {
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
}

/* design process section styling */
.two-design-section {
    display: flex;
}

.two-design-images img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    border: 2px solid #7C4DFF;
    min-width: 325px;
    margin-left: 10px;
    margin-right: 10px;
}

.two-design-images figcaption {
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}

/* prototypes section styling */
.two-prototypes-images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.prototype-image-section {
    display: flex;
    justify-content: center;
    width: 100%;
}

.prototype-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
}

.prototype-image img {
    width: 110%;
    height: auto;
}

@media only screen and (min-width: 848px) and (max-width: 1048px) {

    /* title styling */
    .project2-heading-container {
        height: 75px;
        left: 0;
    }

    #project2-heading-1 {
        z-index: 0;
    }

    #project2-heading-2 {
        top: 23px;
        left: 50px;
        z-index: 1;
    }

    /* design process section styling */
    .two-design-images img {
        min-width: 0;
        width: 60%;
        margin: 0 auto;
    }

    .two-design-images figure {
        align-items: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .two-design-section {
        flex-direction: column;
    }

    /* prototypes section styling */
    .prototype-image-section {
        flex-direction: column;
    }

    .prototype-image-section, .prototype-image {
        width: 100%;
    }

    .prototype-image img {
        width: 80%;
        height: auto;
    }
}

/* Media query for smaller screens */
@media only screen and (max-width: 848px) {

    /* intro section styling */
    .two-intro-section {
        flex-direction: column;
    }

    .two-intro-image {
        border-bottom: 2px solid #D1C4E9;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        width: 100%;
    }

    .two-intro-text {
        padding-left: 10px;
        padding-right: 10px;
    }

    .two-prototype-link {
        flex-direction: column;
        align-items: center;
    }

    .two-intro-image img {
        min-width: 0;
        border-top-right-radius: 20px;
    }

    .two-overview {
        padding-right: 0;
    }

    .two-intro-image img {
        border-bottom-left-radius: 0;
    }

    /* title styling */
    .project2-heading-container {
        left: 15px;
    }

    /* wireframes styling section */
    .two-wireframes-images {
        flex-direction: column;
    }

    .wireframe-image {
        width: 80%;
        margin: 0 auto;
    }

    /* design process section styling */
    .two-design-images img {
        min-width: 0;
        width: 100%;
        margin: 0 auto;
    }

    .two-design-images figure {
        align-items: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .two-design-section {
        flex-direction: column;
    }

    /* prototypes section styling */
    .prototype-image-section {
        flex-direction: column;
    }

    .prototype-image img {
        width: 100%;
        height: auto;
    }

    .prototype-image-section, .prototype-image {
        width: 100%;
    }
}

/* Media query for title on small screens */
@media only screen and (max-width: 700px) {

    
    /* heading styling */
    #project2-heading-2-shortened {
        display: block;
        font-family: 'Parisienne', cursive;
        font-size: 65px;
        color: #AB90F1;
        position: absolute;
        top: -12px; 
        left: 250px; 
        margin: 0;
        z-index: 0;
    }

    #project2-heading-2 {
        display: none;
    }

    /* goals and audience section styling */
    .two-goals-audience-section {
        flex-direction: column;
    }

    .two-audience {
        padding-left: 0;
    }

    /* wireframes styling section */
    .wireframe-image {
        width: 100%;
    }
}
