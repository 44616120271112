.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 1rem;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.logo {
    order: 3; /* Move logo to the right */
    display: flex;
    align-items: center;
}

.logo img {
    max-height: 50px;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 1rem;
    margin: 0;
    padding: 0;
    order: 2; /* Move navigation to the left */
}

.nav-links a {
    color: #EAEAEA;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
    font-family: 'Comfortaa', 'sans-serif';
}

.nav-links a:hover {
    color: #7C4DFF;
}

.hamburger-icon {
    order: 1;
    display: none;
    cursor: pointer;
}

/* Media query for responsive design */
@media (max-width: 768px) {
    .navbar {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
    }

    .nav-links {
        order: 3;
        width: 100%;
        margin-top: 1rem;
        display: none;
        flex-direction: column;
        gap: 0.5rem;
    }

    .nav-links.active {
        display: flex;
    }

    .hamburger-icon img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .hamburger-icon {
        order: 1;
        display: block;
        width: 30px;
        height: 30px;
        margin-bottom: 0;
    }

    .logo {
        order: 2;
        margin: 0; 
    }

    .logo img {
        max-width: 50px;
        width: 100%;
        height: auto; /* Adjust logo size as needed */
    }
}

