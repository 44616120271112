/* Project 1 styles */

/* whole section styles */
.project1-section {
    background-color: #1C1C1C;
    color: #EAEAEA;
    padding: 20px;
    font-family: 'Comfortaa', 'sans-serif';
    display: flex;
    justify-content: center;
}

/* title styles */
.project1-heading-container {
    position: relative;
    width: 100%;
    margin-left: -20px;
    height: 50px; /* Adjust the height to accommodate both texts */
}

#project1-heading-1 {
    font-family: 'Marcellus', serif;
    font-size: 48px;
    color: #D1C4E9;
    position: absolute;
    top: 0;
    left: 20px;
    margin: 0;
    z-index: 1;
}

#project1-heading-2 {
    font-family: 'Parisienne', cursive;
    font-size: 65px;
    color: #AB90F1;
    position: absolute;
    top: -12px; 
    left: 110px; 
    margin: 0;
    z-index: 0;
}

/* heading styles */
.project1-section h1 {
    font-family: 'Parisienne', cursive;
    font-size: 48px;
    color: #AB90F1;
}

.project1-section h2 {
    font-family: 'Marcellus', serif;
    font-size: 28px;
    margin-bottom: 0px;
}

#one-overview-h3 {
    font-family: Comfortaa, sans-serif !important;
    font-size: 20px !important;
}

.project1-section h3 {
    font-size: 22px;
    font-family: 'Marcellus', serif;
}

/* paragraph styling */
.project1-section p {
    font-size: 16px;
    line-height: 1.5;
}

/* list styling */
.project1-section li {
    font-size: 16px;
    line-height: 1.5;

}

/* link styling */
.project1-section a {
    color: #AB90F1;
    text-decoration: none;
}

.project1-section a:hover {
    text-decoration: underline;
}

/* button styling */
.project1-section button {
    background-color: #1C1C1C;
    border: 2px solid #AB90F1;
    border-radius: 5px;
    padding: 10px;
    font-family: 'Comfortaa', sans-serif;
    cursor: pointer;
    width: 200px;
    color: #EAEAEA;
    font-size: 16px;
}

.project1-section button:hover {
    background-color: #AB90F1;
    color: #1C1C1C;
}

/* content styling */
.project1-content {
    flex: 1;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* intro section styling */
.one-intro-section {
    display: flex;
    background-color: #EAEAEA;
    border-radius: 20px;
}

.one-title {
    margin-top: 20px;
}

.one-overview {
    padding-right: 30px;
}

.one-intro-text {
    color: #1C1C1C;
}

.one-intro-image {
    margin-right: 1.5rem;
   
}

.one-intro-image img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    min-width: 400px;
    vertical-align: bottom;
    object-fit: cover;
}

#one-prototype-line {
    color: #AB90F1;
    padding-right: 10px;
}

.one-prototype-link {
    display: flex;
    padding: 5px;
    justify-content: center;
}

.one-prototype-link a {
    padding: 5px;
}

/* user research section styling */
.one-research-image {
    display: flex;
    justify-content: center;
    padding: 10px;
    padding-top: 20px;
}

.one-research-image img {
    border-radius: 20px;
    border: 2px solid #7C4DFF;
    width: 80%;
    height: auto;
}

/* market research section styling */
.one-market-images {
    display: flex;
    align-items: center;
    padding: 10px;
}

.one-market-images img {
    border-radius: 20px;
    border: 2px solid #7C4DFF;
    width: 50%;
    height: auto;
    margin: 5px;
}

/* user personas and journey map styling */
.one-personas-image {
    display: flex;  
    justify-content: center;
    padding: 10px;
}

.one-personas-image img {
    border-radius: 20px;
    border: 2px solid #7C4DFF;
    width: 80%;
    height: auto;
    margin: 5px;
}

/* wireframes styling */
.one-wireframes-images {
    display: flex;
    justify-content: center;
}

.paper-wireframe {
    flex: 1.8;
}

.digital-wireframe {
    flex: 1.5;
}

.one-wireframes-images img {
    width: 110%;
    height: auto;
    object-fit: cover;
}

#paper-wireframe img {
    border-radius: 20px;
    border: 2px solid #7C4DFF;
}

#paper-wireframe figcaption {
    margin-top: 10px;
}

#digital-wireframe figcaption {
    margin-top: -5px;
}

.one-wireframes figcaption {
    font-size: 14px;
    text-align: center;
}

/* prototype section styling */
.one-prototype-mockups {
    display: flex;
}

.one-prototype-text {
    flex-direction: column;
}

.one-prototype-mockups img {
    border-radius: 20px;
    border: 2px solid #7C4DFF;
    width: 100%;
    height: auto;
    margin: 10px;
    object-fit: cover;
}

.one-prototype-high-fidelity {
    display: flex;
    justify-content: center;
    padding: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    
    /* market research section styling */
    .one-market-images img {
        width: 80%;
    }

    .one-market-images {
        flex-direction: column;
    }

    /* wireframes and prototypes styling */
    .one-wireframes-images {
        flex-direction: column;
    }

    .one-wireframes-images img {
        width: 100%;
    }

    /* prototypes section styling */
    .one-prototype-mockups {
        flex-direction: column;
    }

    .one-prototype-mockups img {
        width: 80%;
        margin: 0 auto;
    }

    .one-prototype-high-fidelity {
        flex-direction: column;
    }
}

/* Media query for smaller screens */
@media only screen and (max-width: 768px) {

    /* intro section styling */
    .one-intro-section {
        flex-direction: column;
    }

    .one-intro-image {
        border-bottom: 2px solid #D1C4E9;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        width: 100%;
    }

    .one-intro-text {
        padding: 1rem;
        padding-top: 0;
    }

    .one-prototype-link {
        flex-direction: column;
        align-items: center;
    }

    .one-intro-image img {
        min-width: 0;
    }

    .one-overview {
        padding-right: 0;
    }

    /* user research section styling */
    .one-research-image img { 
        width: 100%;
    }

    /* market research section styling */
    .one-market-images img {
        width: 100%;
    }

    .one-market-images {
        flex-direction: column;
    }

    /* wireframes and prototypes styling */
    .one-wireframes-images {
        flex-direction: column;
    }

    .one-wireframes-images img {
        width: 100%;
    }

    /* prototypes section styling */
    .one-prototype-mockups {
        flex-direction: column;
    }

    .one-prototype-mockups img {
        width: 80%;
        margin: 0 auto;
    }

    .one-prototype-high-fidelity {
        flex-direction: column;
    }
}
