
.about-section {
    background-color: #1C1C1C;
    color: #1C1C1C;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
}

.about-heading-container {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    height: 100px; /* Adjust the height to accommodate both texts */
}

#about-heading-1 {
    font-family: 'Marcellus', serif;
    font-size: 48px;
    color: #D1C4E9;
    position: absolute;
    top: 0px;
    margin: 0;
    z-index: 0;
}

#about-heading-2 {
    font-family: 'Parisienne', cursive;
    font-size: 60px;
    color: #AB90F1;
    position: absolute;
    top: -5px;
    left: 115px;
    margin: 0;
    z-index: 1;
}

.about-content {
    background-color: #EAEAEA;
    display: flex;
    align-items: center;
    border-radius: 20px;
    width: 80%;
    max-width: 1500px;
    position: relative;
}

.about-image {
    flex: 1;
    display: flex;
    align-items: flex-end;
    position: relative;
    height: 100%;
}

.sparkle {
    position: absolute;
    width: 100%;
    height: auto;
    max-width: 200px;
    transform: rotate(120deg);
    top: -1px;
    left: -50px;
}

.heading-container {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    height: 100px; /* Adjust the height to accommodate both texts */
}

#heading-1 {
    font-family: 'Parisienne', cursive;
    font-size: 60px;
    color: #AB90F1;
    position: absolute;
    top: 0;
    left: 20px;
    margin: 0;
    z-index: 1;
}

#heading-2 {
    font-family: 'Marcellus', serif;
    font-size: 48px;
    color: #D1C4E9;
    position: absolute;
    top: 50px;
    left: 65px;
    margin: 0;
    z-index: 0;
}

.about-content h3 {
    font-family: 'Comfortaa', 'sans-serif';
    font-size: 20px;
    margin-top: 20px;
    padding-left: 20px;
}

.about-text {
    flex: 2;
    max-width: 1000px;
    margin: auto;
    padding: 20px;
    font-family: 'Comfortaa', 'sans-serif';
}

.about-text p {
    font-size: 16px;
    line-height: 1.5;
    padding-right: 20px;
    padding-left: 20px;
}

.lindsey {
    height: 500px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1300px) {

    .about-content {
        flex-direction: column;
    }

    .about-image {
        margin-top: 20px;
        margin-left: 20px;
    }

    .about-text {
        padding: 20px;
    }

    .sparkle {
        top: -10px;
        left: -40px;
    }

    .lindsey {
        width: 100%;
        height: auto;
        max-width: 500px;
        object-fit: cover;
    }
}

@media (max-width: 600px) {
    .sparkle {
        max-width: 150px;
        left: -20px;
    }
}