.skills-section {
    background-color: #1C1C1C;
    color: #EAEAEA;
    padding: 30px;
    font-family: 'Comfortaa', 'sans-serif';
}

.skills-heading-container {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    height: 100px; /* Adjust the height to accommodate both texts */
}

#skills-heading-1 {
    font-family: 'Marcellus', serif;
    font-size: 48px; 
    color: #D1C4E9;
    position: absolute;
    top: 0;
    margin: 0;
    z-index: 0;
}

#skills-heading-2 {
    font-family: 'Parisienne', cursive;
    font-size: 60px;
    color: #AB90F1;
    position: absolute;
    top: -5px; 
    left: 62px; 
    margin: 0;
    z-index: 1;
}

.skills-image {
    margin-right: 40px;
    width: 100%;
    height: auto;
    max-width: 700px;
}

.skills-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.skill-category {
    max-width: 500px;
    margin: 10px; /* Added margin for better spacing between divs */
}

.skill-category h2 {
    font-size: 30px;
    margin-bottom: 10px;
   
    font-family: 'Marcellus', serif;
}

.skill-category ul {
    list-style-type: circle;
    padding: 10px;
    padding-left: 40px; /* Adjust padding to ensure bullets are inside the border */
    margin: 0; /* Remove margin */
    border: 2px solid #AB90F1;
    border-radius: 20px;

}

.skill-category ul li {
    font-size: 16px;
    margin: 8px 0;
    display: list-item; /* Ensure list items display as list-item */
}

