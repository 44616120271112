.footer {
    display: flex;
    background-color: #333;
    justify-content: center;
    align-items: center;
    color: #EAEAEA;
    padding: 20px;
    flex-direction: column;
    font-size: 16px;
    font-family: 'Comfortaa', 'sans-serif';
}

.icons {
    display: flex;
    gap: 10px;
}

.icon {
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
}

