/* Project 3 Styles */

/* whole section styling*/
.project3-section {
    background-color: #1C1C1C;
    color: #EAEAEA;
    padding: 20px;
    font-family: 'Comfortaa', 'sans-serif';
    display: flex;
    justify-content: center;
}

/* title styling */
.project3-heading-container {
    position: relative;
    width: 100%;
    margin-left: -20px;
    height: 50px; /* Adjust the height to accommodate both texts */
}

#project3-heading-1 {
    font-family: 'Marcellus', serif;
    font-size: 48px;
    color: #D1C4E9;
    position: absolute;
    top: 0;
    left: 20px;
    margin: 0;
    z-index: 1;
}

#project3-heading-2 {
    font-family: 'Parisienne', cursive;
    font-size: 65px;
    color: #AB90F1;
    position: absolute;
    top: -12px; 
    left: 100px; 
    margin: 0;
    z-index: 0;
}

/* heading styles */
.project3-section h1 {
    font-family: 'Parisienne', cursive;
    font-size: 48px;
    color: #AB90F1;
}

.project3-section h2 {
    font-family: 'Marcellus', serif;
    font-size: 28px;
    margin-bottom: 0px;
}

#overview-h3 {
    font-family: Comfortaa, sans-serif !important;
    font-size: 20px !important;
}

.project3-section h3 {
    font-size: 22px;
    font-family: 'Marcellus', serif;
}

.project3-section h4 {
    font-size: 18px;
}

/* paragraph styling */
.project3-section p {
    font-size: 16px;
    line-height: 1.5;
}

/* list styling */
.project3-section li {
    font-size: 16px;
    line-height: 1.5;

}

/* link styling */
.project3-section a {
    color: #AB90F1;
    text-decoration: none;
}

.project3-section a:hover {
    text-decoration: underline;
}

/* button styling */
.project3-section button {
    background-color: #1C1C1C;
    border: 2px solid #AB90F1;
    border-radius: 5px;
    padding: 10px;
    font-family: 'Comfortaa', sans-serif;
    cursor: pointer;
    width: 200px;
    color: #EAEAEA;
    font-size: 16px;
}

.project3-section button:hover {
    background-color: #AB90F1;
    color: #1C1C1C;
}

/* content styling */
.project3-content {
    flex: 1;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* intro section styling */
.three-intro-section {
    display: flex;
    background-color: #EAEAEA;
    border-radius: 20px;
}

.three-title {
    margin-top: 20px;
}

.three-overview {
    padding-right: 30px;
}

.three-intro-text {
    color: #1C1C1C;
}

.three-intro-image {
    margin-right: 1.5rem;  
}

.three-intro-image img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    min-width: 400px;
    vertical-align: bottom;
    object-fit: cover;
}

.three-link-line {
    color: #AB90F1;
    padding-right: 10px;
}

.three-prototype-link, .three-walkthrough {
    display: flex;
    padding: 5px;
    justify-content: center;
}

.three-prototype-link a, .three-walkthrough a {
    padding: 5px;
}

/* discovery section styling */
.three-discovery-section {
    display: flex;
    align-items: stretch;
}

.three-discovery-section-2 {
    padding-left: 40px;
}

/* design section styling */

.three-wireframes-image, .three-lf-prototype-image, .three-hf-prototype-image {
    display: flex;
    justify-content: center;
}

.three-wireframes-image img, .three-lf-prototype-image img, .three-hf-prototype-image img {
    width: 100%;
    height: auto;
    margin-top: -30px;
}

.three-wireframes figcaption, .three-lf-prototype figcaption, .three-hf-prototype-image figcaption {
    font-size: 14px;
    text-align: center;
}

.three-design-guide-image, .final-design-guide-image {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.three-design-guide-image img, .final-design-guide-image img {
    border-radius: 20px;
    border: 2px solid #7C4DFF;
    width: 80%;
    height: auto;
}

.three-design-guide {
    display: flex;
}

.three-design-guide-text {
    width: 40%;
}

/* use cases section styling */
.site-architecture img {
    width: 100%;
    height: auto;
}

.site-architecture figcaption {
    font-size: 14px;
    text-align: center;
}

/* finalization section styling */
.final-site-map img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    border: 2px solid #7C4DFF;
    margin-top: 5px;
    margin-bottom: 10px;
}

.final-site-map figcaption {
    font-size: 14px;
    text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  
    /* discovery section styling */
    .three-discovery-section {
        display: flex;
        flex-direction: column;
    }

    .three-discovery-section-2 {
        padding-left: 0px;
    }
}

/* Media query for smaller screens */
@media only screen and (max-width: 768px) {

    /* intro section styling */
    .three-intro-section {
        flex-direction: column;
    }

    .three-intro-image {
        border-bottom: 2px solid #D1C4E9;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        width: 100%;
    }

    .three-intro-text {
        padding: 1rem;
        padding-top: 0;
    }

    .three-prototype-link {
        flex-direction: column;
        align-items: center;
    }

    .three-intro-image img {
        min-width: 0;
    }

    .three-overview {
        padding-right: 0;
    }

    /* discovery section styling */
    .three-discovery-section {
        display: flex;
        flex-direction: column;
    }

    .three-discovery-section-2 {
        padding-left: 0px;
    }

    /* design section styling */
    .three-design-guide {
        flex-direction: column;
    }
    
    .three-design-guide-text {
        width: 100%;
    }

    .three-design-guide-image, .final-design-guide-image  {
        padding-left: 0px;
    }

    .three-design-guide-image img, .final-design-guide-image img  {
        width: 100%;
    }
    
}