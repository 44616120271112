/* projects section css */

/* whole section */
.projects-section {
    background-color: #1C1C1C;
    color: #EAEAEA;
    padding: 30px;
    font-family: 'Comfortaa', 'sans-serif';
}

/* for the heading */
.projects-heading-container {
    position: relative;
    width: 100%;
    text-align: center;
    height: 100px;  
}

/* first word in heading */
#projects-heading-1 {
    font-family: 'Marcellus', serif;
    font-size: 48px;
    color: #D1C4E9;
    position: absolute;
    top: 0;
    margin: 0;
    z-index: 0;
}

/* second word in heading */
#projects-heading-2 {
    font-family: 'Parisienne', cursive;
    font-size: 60px;
    color: #AB90F1;
    position: absolute;
    top: 0; 
    left: 55px; 
    margin: 0;
    z-index: 1;
}

/* all projects and its heading */
.projects-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

/* individual project */
.project {
    width: 30%;
    margin: .5em;
}

/* project heading */
.project h2 {
    font-size: 2rem;
    color: #EAEAEA;
    font-family: 'Marcellus', serif;
}

/* content in the project */
.project-item {
    border-radius: 1.25rem;
    overflow: hidden;
    background-color: #D1C4E9;
    padding-bottom: .5em;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* for hover effect */
    text-align: left;
}

/* hover effect for project */
.project-item:hover {
    transform: translateY(-5px); /* moves box slightly upwards */
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.7); /* adds shadow */
}

/* subheading */
.project-item h3 {
    font-family: 'Comfortaa', 'sans-serif';
    font-size: 1.25em;
    margin-bottom: .5em;
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    color: #1C1C1C;
}

/* project image */
.project-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

/* project text */
.card-text {
    font-size: 1em;
    color: #1C1C1C;
    padding: 1.2rem;
    padding-top: 0px;
    padding-bottom: 1rem;
    line-height: 1.5;
}

/* project link */
.projects-section a {
    display: block;
    text-align: center;
    font-size: 1em;
    padding-bottom: 1em;
    text-decoration: none;
    color: #7C4DFF;
}

/* hover effect for project link */
.projects-section a:hover {
    text-decoration: underline;
}

/* media query for smaller screens */
@media (max-width: 844px) {

    /* whole section */
    .projects-content {
        flex-direction: column;
    }

    /* individual project */
    .project {
        width: 100%;
    }
}